<!--费用标准-->
<template>
  <div class="bgblue feeStandard">


    <el-card class="box-card" style="margin-right: 7px">
      <!-- 头部模块 -->
      <el-form class="el-form-margin0" label-width="120px">
        <el-row :gutter="40" align="center">
          <el-col :span="8">
            <el-form-item label="初发货日期:">
              <el-date-picker style="width: 100%; " v-model="value1" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <!-- <el-radio-group v-model="radio6" size="mini">
              <el-radio-button label="今日"></el-radio-button>
              <el-radio-button label="昨日"></el-radio-button>
              <el-radio-button label="本周"></el-radio-button>
              <el-radio-button label="上周"></el-radio-button>
              <el-radio-button label="本月"></el-radio-button>
              <el-radio-button label="上月"></el-radio-button>
            </el-radio-group> -->
            <el-button>今日</el-button>
            <el-button>昨日</el-button>
            <el-button>本周</el-button>
            <el-button>上周</el-button>
            <el-button>本月</el-button>
            <el-button>上月</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="margin-top: 15px;">
            <div style="width: 120px; text-align: right; display: inline-block">
              <span class="f12 ft">地区:&nbsp;</span>
            </div>
            <div role="radiogroup" class="el-radio-group">
              <el-radio v-model="radio2" label="1" border size="mini" class="">合肥市</el-radio>
              <el-radio v-model="radio2" label="2" border size="mini">阜阳市</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="mt20">
            <div style="width: 120px; text-align: right; display: inline-block">
              <span class="f12 ft">安装模式:&nbsp;</span>
            </div>
            <div role="radiogroup" class="el-radio-group">
              <el-radio v-model="radio3" label="1" border size="mini" class="">常规列阵</el-radio>
              <el-radio v-model="radio3" label="2" border size="mini">南北坡</el-radio>
              <el-radio v-model="radio3" label="3" border size="mini">阳光房</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="mt20">
            <div style="width: 120px; text-align: right; display: inline-block">
              <span class="f12 ft">备案模式:&nbsp;</span>
            </div>
            <div role="radiogroup" class="el-radio-group">
              <el-radio v-model="radio4" label="1" border size="mini" class="">项目公司</el-radio>
              <el-radio v-model="radio4" label="2" border size="mini">自然人</el-radio>
              <el-radio v-model="radio4" label="3" border size="mini">公共屋顶</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" class="mt20">
            <div style="width: 120px; text-align: right; display: inline-block">
              <span class="f12 ft">组件类型:&nbsp;</span>
            </div>
            <div role="radiogroup" class="el-radio-group">
              <el-radio v-model="radio5" label="1" border size="mini" class="">550W系列</el-radio>
              <el-radio v-model="radio5" label="2" border size="mini">575W系列</el-radio>
              <el-radio v-model="radio5" label="3" border size="mini">660W系列
              </el-radio>
              <el-radio v-model="radio5" label="4" border size="mini" class="">590W系列</el-radio>
              <el-radio v-model="radio5" label="5" border size="mini">690W系列</el-radio>
              <el-radio v-model="radio5" label="6" border size="mini">620W系列
              </el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="padding-left: 70px;" class="mt20">
            <el-button type="primary" icon="el-icon-search" @click="toSearch">查询</el-button>
            <el-button icon="el-icon-refresh" @click="toReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card" style="margin: 7px 10px 7px 0">

      <!-- 表格模块 -->
      <el-table :data="tableData" height="calc(100vh - 250px)" border>


        <el-table-column prop="agentName" label="省份" align="center">
        </el-table-column>
        <el-table-column prop="batchNo" label="区域" align="center">
        </el-table-column>
        <el-table-column prop="status" label="组件型号" align="center">

        </el-table-column>
        <el-table-column prop="settlementType" label="市级

" align="center">
        </el-table-column>
        <el-table-column prop="settlementAmount" label="安装模式" align="center">
        </el-table-column>
        <el-table-column prop="settlementComboAmount" label="备案模式" align="center">
        </el-table-column>
        <el-table-column prop="changeReduce" label="安装方式合集" align="center">
        </el-table-column>
        <el-table-column prop="rebateReduce" label="基础价格" align="center">
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage" :page-sizes="[10, 20, 50, 100]" :page-size="2"
        layout="total, sizes, prev, pager, next, jumper" :total="total" style="text-align: right;">
      </el-pagination>
    </el-card>




  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: "",
        },
      },
      radio6: "本月",
      radio1: "1",
      radio2: "1",
      radio3: "1",
      radio4: "1",
      radio5: "1",
      settleQueryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: "",
        },
      },
      tableData: [],
      settleTableData: [],
      total: 0,
      settleTotal: 0,
      settlementVisible: false, // 结算弹窗
      multipleSelection: [],
      moduleNumTotal: 0, //组件总片数
      totalPrice: 0,
      viewVisible: false,
      invoiceVisible: false,
      dialogVisible: false,
      dialogImageUrl: "",
      invoiceId: "",
      detailList: [],
      invoiceForm: {
        iconUrl: "",
      },
      IULoading: false,
      viewFrom: {},
    };
  },
  created() {
    this.getList();
    this.queryInfo.condition.userCode = JSON.parse(
      localStorage.getItem("userInfo")
    ).userInfo.userCode;
    this.settleQueryInfo.condition.agentCode = JSON.parse(
      localStorage.getItem("userInfo")
    ).userInfo.agentCode;
  },
  methods: {
    getList() {
      this.$http
        .post("/houseAgentBillSettlement/list", this.queryInfo)
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.count;
          }
        });
    },
    //动态合并表格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let res in this.needMergeArr) {
        if (this.needMergeArr[res] == column.property) {
          return this.mergeAction(column.property, rowIndex, columnIndex);
        }
      }
    },
    mergeAction(val, rowIndex) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    // 修改页数大小
    settleSizeChange(val) {
      this.settleQueryInfo.pageSize = val;
      this.getSettleList();
    },

    // 获取当前页面
    settleCurrentChange(val) {
      this.settleQueryInfo.currPage = val;
      this.getSettleList();
    },
    toSearch() {
      this.getList();
    },
    toReset() {
      this.queryInfo.condition = {};
      this.getList();
    },
    openSettleDialog() {
      this.settlementVisible = true;
      this.getSettleList();
    },
    getSettleList() {
      this.$http
        .post("houseAgentBill/noSettlementList", this.settleQueryInfo)
        .then((res) => {
          if (res.data.code == 200) {
            this.settleTableData = res.data.data.data;
            this.settleTotal = res.data.data.count;
          }
        });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
      this.settlementSummary();
    },
    toSettle() {
      this.$confirm("确定提交结算吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let userInfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
          let data = {
            agentCode: userInfo.agentCode,
            agentName: userInfo.agentName,
            billList: this.multipleSelection,
          };
          this.$http
            .post("houseAgentBillSettlement/batchSettlementList", data)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success("提交成功！");
                this.settlementVisible = false;
                this.getList();
              }
            });
        })
        .catch(() => { });
    },
    settleSearch() {
      this.getSettleList();
    },
    settlementSummary() {
      let moduleNumTotal = 0;
      let totalPrice = 0;
      this.multipleSelection.forEach((item) => {
        moduleNumTotal = moduleNumTotal + item.moduleNum;
        totalPrice = totalPrice + item.payPrice;
      });

      this.moduleNumTotal = moduleNumTotal;
      this.totalPrice = totalPrice;
    },
    deleteCustomerName(index) {
      let row = this.multipleSelection[index];
      let rows = this.settleTableData.find((element) => {
        return element.id === row.id;
      });
      this.$refs.multipleTable.toggleRowSelection(rows, false);
    },
    openView(row) {
      this.viewVisible = true;
      this.viewFrom = row;
    },
    toShowInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceId = row.id;
      if (row.invoiceUrl) {
        this.detailList = JSON.parse(row.invoiceUrl);
      }
    },
    handleImageUrlPreview(e, index) {
      e.stopPropagation();
      this.dialogImageUrl = this.detailList[index].imageUrl;
      this.dialogVisible = true;
    },
    handleImageRemove(e, index) {
      e.stopPropagation();
      this.$nextTick(() => {
        this.detailList.splice(index, 1);
      });
    },
    submitInvoice() {
      if (this.detailList.length == 0) {
        this.$message.error("请上传发票图片");
      } else {
        this.$http
          .post("/houseAgentBillSettlement/openInoice", {
            id: this.invoiceId,
            invoiceUrl: this.detailList,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success("上传成功！");
              this.invoiceVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    toShowInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceId = row.id;
      if (row.invoiceUrl) {
        this.detailList = JSON.parse(row.invoiceUrl);
      }
    },
    //图片上传成功处理
    handleimageSuccess(val) {
      this.detailList.push({
        imageUrl: val.data.url,
      });
      this.IULoading = false;
    },
    //图片上传之前处理
    beforeUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      // return isJPG && isLt2M;
      return isLt2M;
    },
  },
};
</script>
<style lang="less" scoped>
.feeStandard {
  padding: 10px;
}

.searchBox {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

::v-deep .el-form-item--small .el-form-item__label {
  font-weight: 800 !important;
}

.el-table__body tr.hover-row>td {
  background-color: #f59a23 !important;
}

::v-deep .el-table {
  margin-top: 0px !important;
}

.mt20 {
  margin-top: 20px;
}

.ft {
  font-weight: 800;
}

.selectedName {
  display: flex;
  margin: 10px 0;
}

.customerNameBox {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1;
}

.customerNameItem {
  border: 1px solid #e4e4e4;
  padding: 3px 20px;
  position: relative;
  border-radius: 3px;

  .delete {
    width: 10px;
    height: 10px;
    line-height: 10px;
    background-color: #d71d05;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
  }
}

.summaryContent {
  width: 300px;
  padding: 10px 50px;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-left: 42px;
  border-radius: 5px;
}

.viewTitle {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
}

.viewTitle::after {
  display: block;
  content: "";
  width: 5px;
  height: 20px;
  background-color: #00a5ef;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.viewValue {
  border: 1px solid #e4e4e4;
  // width: 210px;
  padding: 0 10px;
  border-radius: 3px;
  word-wrap: break-word;
}

.viewImg {
  width: 100px;
  height: 100px;
}

.settleAmount {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #8c939d;
  font-size: 11px;
  line-height: 16px;
}

.settleAmount>div {
  margin-right: 8px;
}

.red {
  color: #d71d05;
}

.blue {
  color: #00a5ef;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload {
  border: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.viewContent {
  max-height: 500px;
  overflow-y: scroll;
}

/deep/.el-card__body {
  padding: 7px;
}

/deep/.el-form-item {
  margin-bottom: 7px;
}

/deep/ .el-form-margin0 {
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
